import React from 'react'
import UserResults from '../components/users/UserResults'
import UserSearch from '../components/users/UserSearch'

function home() {
  return (
    <>
      <UserSearch />
      <UserResults />
    </>
  )
}

export default home